<template>
  <v-card flat tile class="mt-2 mx-2"
  >
    <v-toolbar
      dense
      rounded="5"
      color="primary"
      dark
      class="mt-2"
    >
      <v-toolbar-title>Selecciona el tipo de pago</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="info" small @click="regresar"><v-icon>reply</v-icon></v-btn>
    </v-toolbar>

    <v-container
      class="grey lighten-2"
      fluid >

      <v-row justify="center">
        <v-col
          v-for="card in cards"
          :key="card.nompago"
          cols="12"
          sm="4"
          md="4"
        >
          <v-card
            :to= "card.to"
            height="auto"
            class="ma-1 pa-1"
          >
            <v-img
              :src= "card.src"
              height="100px"
              contain
              class="ma-2 pa-2"

            >
              <!-- <span
                class="headline white--text pl-4 pt-4 d-inline-block"
                v-text="card.nompago"
              ></span> -->
            </v-img>

            <v-card-title class="ma-0 pa-2">{{card.nompago}}</v-card-title>

            <v-card-actions class="ma-0">

              <!-- <v-btn
                v-for="(social, i) in socials"
                :key="i"
                :color="social.color"
                class="white--text"
                fab
                icon
                small
              >
                <v-icon>{{ social.icon }}</v-icon>
              </v-btn> -->
              
              <v-card-subtitle class="ma-0 pa-0 red--text">
                {{card.subtitle}}
              </v-card-subtitle>


            </v-card-actions>
             
        </v-card>
      </v-col>

    </v-row>
  </v-container>
</v-card>

</template>

<script>
export default {
  data () {
    return {

      cards: [],
      socials: [
        {
          icon: 'mdi-facebook',
          color: 'indigo'
        },
        {
          icon: 'mdi-linkedin',
          color: 'cyan darken-1'
        },
        {
          icon: 'mdi-instagram',
          color: 'red lighten-3'
        }
      ],

      datosCard: [],

      usapaypal: true,
      usaopenpay: true,
      pagotienda: true,
      creditotienda: true,
      transferencia: true,

      srcOpen: require('@/assets/open.png'),
      srcPaypal: require('@/assets/paypal.png'),
      srcFiserv: require('@/assets/logofiserv.png'),
		  srcTransfer: require('@/assets/botonbanco.png'),
      srcCredito: require('@/assets/botoncredito.png'),
      srcTienda: require('@/assets/botontienda.png'),
      srcOxxo: require('@/assets/oxxo.png')

    }
  },

  created () {
    this.init()
  },

  methods: {

    init () {
      // console.log("init Tipopgao")
      this.cards = []
      // Consultar información de cobro.
      const url = 'auth/api/v1/getcobrosconfig'
      this.loading = true
      this.$http.get(url).then(response => {
        // console.log("getcobrosconfig", response)
        this.cobros = response.data

        if (this.cobros.Usapaypal === '1') {
          this.cards.push({
            nompago: 'Paypal',
            to: { name: 'paypalcheck', params: { tipo: 1 } },
            idpagos: this.cobros.idpaypal,
            env: this.cobros.envpaypal,
            src: this.srcPaypal
          })
        }

        if (this.cobros.usaopenpay === '1') {
          this.cards.push({
            nompago: 'Openpay',
            to: { name: 'openpay', params: { tipo: 1 } },
            idpagos: this.cobros.idpaypal,
            env: this.cobros.envpaypal,
            src: this.srcOpen
          })
        }

        // this.cobros.usafiserv = '1'
        if (this.cobros.usafiserv === '1') {
          this.cards.push({
            nompago: 'Pago con Tarjeta',
            subtitle: 'Meses sin Intereses',
            to: { name: 'fiserv', params: { tipo: 1 } },
            idpagos: this.cobros.idfiserv,
            env: this.cobros.envfiserv,
            src: this.srcFiserv
          })
        }

        if (this.cobros.pagotienda === '1') {
          this.cards.push({
            nompago: 'Depósitar a cuenta',
            subtitle: 'Y subir comprobante',
            to: { name: 'validainfo', params: { tipo: 4, src: this.srcOxxo } },
            idpagos: 7,
            env: 'P',
            src: this.srcOxxo
          })
        }

        if (this.cobros.Transferencia === '1') {
          this.cards.push({
            nompago: 'Transferencia',
            subtitle: 'Enviar comprobante',
            to: { name: 'validainfo', params: { tipo: 2, src: this.srcTransfer } },
            idpagos: 7,
            env: 'P',
            src: this.srcTransfer
          })
        }

        if (this.cobros.creditotienda === '1') {
          this.cards.push({
            nompago: 'Crédito Tienda',
            to: { name: 'validainfo', params: { tipo: 4, src: this.srcCredito } },
            idpagos: 7,
            env: 'P',
            src: this.srcCredito
          })
        }

        if (this.cobros.pagotienda === '1') {
          this.cards.push({
            nompago: 'Pago en Tienda',
            subtitle: 'Pagar al recolectar en tienda',
            to: { name: 'validainfo', params: { tipo: 4 } },
            idpagos: 7,
            env: 'P',
            src: this.srcTienda
          })
        }

        // console.log(this.cards)

        // Usa
        this.usapaypal = !!parseInt(this.cobros.Usapaypal)
        this.usaopenpay = !!parseInt(this.cobros.usaopenpay)
        this.usafiserv = !!parseInt(this.cobros.usafiserv)

        this.pagotienda = !!parseInt(this.cobros.pagotienda)
        this.creditotienda = !!parseInt(this.cobros.creditotienda)
        this.transferencia = !!parseInt(this.cobros.Transferencia)

        // ID
        this.idpaypal = this.cobros.idpaypal
        this.idopenpay = this.cobros.idopenpay
        this.limiteopenpay = this.cobros.limiteopenpay
        this.idfiserv = this.cobros.idfiserv

        // console.log(this.cobros.envpaypal)
        this.envpaypal = this.cobros.envpaypal == 'P' ? 'Production' : 'Sandbox'
        this.envopenpay = this.cobros.envopenpay == 'P' ? 'Production' : 'Sandbox'
        this.envfiserv = this.cobros.envfiserv == 'P' ? 'Production' : 'Sandbox'

        this.loading = false
      }).catch(error => {
        this.snackbar = true; this.color = 'error'
        this.text = 'Problemas para cargar información'
        // console.log(error)
      })
    },

    regresar () {
    		this.$router.push({ name: 'documento' }).catch(error => { console.log(error) })
    }
  }
}
</script>
